var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quotation__container" },
    [
      _c("PageBlock"),
      _vm._v(" "),
      _c("div", { class: { blurred: !_vm.$store.user.loggedIn } }, [
        _vm.step <= 3
          ? _c("ul", { staticClass: "quotation__progressbar" }, [
              _c("li", { class: { active: _vm.step >= 1 } }),
              _vm._v(" "),
              _c("li", { class: { active: _vm.step >= 2 } }),
              _vm._v(" "),
              _c("li", { class: { active: _vm.step >= 3 } }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.step == 1
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitCompany($event)
                  },
                },
              },
              [
                _c("h2", { staticClass: "quotation__subheading" }, [
                  _vm._v("Company Introduction"),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.name,
                          expression: "company.name",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder: "Enter your company name.",
                        required: "",
                      },
                      domProps: { value: _vm.company.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "name",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.description,
                          expression: "company.description",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__textarea",
                      attrs: {
                        placeholder:
                          "What is your copmany all about? Why are you interesting to work with?",
                        required: "",
                      },
                      domProps: { value: _vm.company.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "description",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.sizeOfBusiness,
                          expression: "company.sizeOfBusiness",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder: "Enter company turnover.",
                        required: "",
                      },
                      domProps: { value: _vm.company.sizeOfBusiness },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "sizeOfBusiness",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Website"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.website,
                          expression: "company.website",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        pattern:
                          "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
                        title: "URL is not valid",
                        placeholder: "Enter company website name.",
                      },
                      domProps: { value: _vm.company.website },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "website",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.languagesSpoken,
                          expression: "company.languagesSpoken",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Which languages can you accept a reply in?",
                        required: "",
                      },
                      domProps: { value: _vm.company.languagesSpoken },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "languagesSpoken",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Competitors"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.competitors,
                          expression: "company.competitors",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Who do you consider to be your main competitor(s)?",
                      },
                      domProps: { value: _vm.company.competitors },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "competitors",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "quotation__subheading" }, [
                  _vm._v("Requirements for Product and Production"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("div", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v(
                        "Social Code of Conduct (for suppliers to follow)"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.company.requirementsSocialFile
                      ? _c("div", { staticClass: "quotation__file" }, [
                          _c(
                            "a",
                            {
                              staticClass: "quotation__file-link",
                              attrs: {
                                href:
                                  _vm.$blobUrl +
                                  "/" +
                                  _vm.company.requirementsSocialFile,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.company.requirementsSocialFile.split(
                                      "/"
                                    )[1]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "quotation__file-delete",
                            attrs: { title: "Delete file" },
                            on: {
                              click: function ($event) {
                                _vm.company.requirementsSocialFile = null
                              },
                            },
                          }),
                        ])
                      : _c("input", {
                          staticClass: "quotation__input-file",
                          attrs: {
                            type: "file",
                            placeholder: "Upload file",
                            "data-obj": "company",
                            "data-ref": "requirementsSocialFile",
                            accept:
                              ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                          },
                          on: { change: _vm.onFileChange },
                        }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.requirementsSocialText,
                          expression: "company.requirementsSocialText",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Comment and/or reference to third party organization.",
                      },
                      domProps: { value: _vm.company.requirementsSocialText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "requirementsSocialText",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("div", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Chemical substance restrictions"),
                    ]),
                    _vm._v(" "),
                    _vm.company.requirementsChemicalFile
                      ? _c("div", { staticClass: "quotation__file" }, [
                          _c(
                            "a",
                            {
                              staticClass: "quotation__file-link",
                              attrs: {
                                href:
                                  _vm.$blobUrl +
                                  "/" +
                                  _vm.company.requirementsChemicalFile,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.company.requirementsChemicalFile.split(
                                      "/"
                                    )[1]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "quotation__file-delete",
                            attrs: { title: "Delete file" },
                            on: {
                              click: function ($event) {
                                _vm.company.requirementsChemicalFile = null
                              },
                            },
                          }),
                        ])
                      : _c("input", {
                          staticClass: "quotation__input-file",
                          attrs: {
                            type: "file",
                            placeholder: "Upload file",
                            "data-obj": "company",
                            "data-ref": "requirementsChemicalFile",
                            accept:
                              ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                          },
                          on: { change: _vm.onFileChange },
                        }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.requirementsChemicalText,
                          expression: "company.requirementsChemicalText",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Comment and/or reference to third party organization.",
                      },
                      domProps: { value: _vm.company.requirementsChemicalText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "requirementsChemicalText",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("div", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Physical product requirements"),
                    ]),
                    _vm._v(" "),
                    _vm.company.requirementsPhysicalFile
                      ? _c("div", { staticClass: "quotation__file" }, [
                          _c(
                            "a",
                            {
                              staticClass: "quotation__file-link",
                              attrs: {
                                href:
                                  _vm.$blobUrl +
                                  "/" +
                                  _vm.company.requirementsPhysicalFile,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.company.requirementsPhysicalFile.split(
                                      "/"
                                    )[1]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "quotation__file-delete",
                            attrs: { title: "Delete file" },
                            on: {
                              click: function ($event) {
                                _vm.company.requirementsPhysicalFile = null
                              },
                            },
                          }),
                        ])
                      : _c("input", {
                          staticClass: "quotation__input-file",
                          attrs: {
                            type: "file",
                            placeholder: "Upload file",
                            "data-obj": "company",
                            "data-ref": "requirementsPhysicalFile",
                            accept:
                              ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                          },
                          on: { change: _vm.onFileChange },
                        }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.requirementsPhysicalText,
                          expression: "company.requirementsPhysicalText",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Comment and/or reference to third party organization.",
                      },
                      domProps: { value: _vm.company.requirementsPhysicalText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "requirementsPhysicalText",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("div", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Environmental requirements"),
                    ]),
                    _vm._v(" "),
                    _vm.company.requirementsEnvironmentalFile
                      ? _c("div", { staticClass: "quotation__file" }, [
                          _c(
                            "a",
                            {
                              staticClass: "quotation__file-link",
                              attrs: {
                                href:
                                  _vm.$blobUrl +
                                  "/" +
                                  _vm.company.requirementsEnvironmentalFile,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.company.requirementsEnvironmentalFile.split(
                                      "/"
                                    )[1]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "quotation__file-delete",
                            attrs: { title: "Delete file" },
                            on: {
                              click: function ($event) {
                                _vm.company.requirementsEnvironmentalFile = null
                              },
                            },
                          }),
                        ])
                      : _c("input", {
                          staticClass: "quotation__input-file",
                          attrs: {
                            type: "file",
                            placeholder: "Upload file",
                            "data-obj": "company",
                            "data-ref": "requirementsEnvironmentalFile",
                            accept:
                              ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                          },
                          on: { change: _vm.onFileChange },
                        }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.company.requirementsEnvironmentalText,
                          expression: "company.requirementsEnvironmentalText",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Comment and/or reference to third party organization.",
                      },
                      domProps: {
                        value: _vm.company.requirementsEnvironmentalText,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.company,
                            "requirementsEnvironmentalText",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(5),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step == 2
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.nextStep($event)
                  },
                },
              },
              [
                _c("h2", { staticClass: "quotation__subheading" }, [
                  _vm._v("Product Information"),
                ]),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(7),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.productName,
                          expression: "quotation.productName",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder: "Enter Product Name.",
                        required: "",
                      },
                      domProps: { value: _vm.quotation.productName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "productName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("div", { staticClass: "quotation__label" }, [
                    _vm._m(8),
                    _vm._v(" "),
                    _vm.quotation.productSketch
                      ? _c("div", { staticClass: "quotation__file" }, [
                          _c(
                            "a",
                            {
                              staticClass: "quotation__file-link",
                              attrs: {
                                href:
                                  _vm.$blobUrl +
                                  "/" +
                                  _vm.quotation.productSketch,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.quotation.productSketch.split("/")[1]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "quotation__file-delete",
                            attrs: { title: "Delete file" },
                            on: {
                              click: function ($event) {
                                _vm.quotation.productSketch = null
                              },
                            },
                          }),
                        ])
                      : _c("input", {
                          staticClass: "quotation__input-file",
                          attrs: {
                            type: "file",
                            placeholder: "Upload file",
                            "data-obj": "quotation",
                            "data-ref": "productSketch",
                            accept: "image/*,.pdf",
                            required: "",
                          },
                          on: { change: _vm.onFileChange },
                        }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(9),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quotation.productType,
                            expression: "quotation.productType",
                          },
                        ],
                        staticClass: "quotation__select",
                        class: {
                          "quotation__select--placeholder":
                            !_vm.quotation.productType,
                        },
                        attrs: { required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.quotation,
                              "productType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", disabled: "" } }, [
                          _vm._v("Select from dropdown."),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.footwearTypes, function (ft, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: ft.title } },
                            [_vm._v(_vm._s(ft.title))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Production Process"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quotation.productProductionProcess,
                            expression: "quotation.productProductionProcess",
                          },
                        ],
                        staticClass: "quotation__select",
                        class: {
                          "quotation__select--placeholder":
                            !_vm.quotation.productProductionProcess,
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.quotation,
                              "productProductionProcess",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", disabled: "" } }, [
                          _vm._v("Select desired production process."),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.processes, function (process, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: process.title } },
                            [_vm._v(_vm._s(process.title))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Materials Upper"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.productMaterialsUpper,
                          expression: "quotation.productMaterialsUpper",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder: "What is the main material for the upper?",
                      },
                      domProps: { value: _vm.quotation.productMaterialsUpper },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "productMaterialsUpper",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Materials Sole"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.productMaterialsSole,
                          expression: "quotation.productMaterialsSole",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "What is the main material for the outsole?",
                      },
                      domProps: { value: _vm.quotation.productMaterialsSole },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "productMaterialsSole",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Prefered Country/Region"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quotation.preferedRegion,
                            expression: "quotation.preferedRegion",
                          },
                        ],
                        staticClass: "quotation__select",
                        class: {
                          "quotation__select--placeholder":
                            !_vm.quotation.preferedRegion,
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.quotation,
                              "preferedRegion",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "", disabled: "", selected: "" } },
                          [_vm._v("Enter if any prefered production region.")]
                        ),
                        _vm._v(" "),
                        _c(
                          "option",
                          {
                            staticClass: "quotation__select-separator",
                            attrs: { disabled: "" },
                          },
                          [_vm._v("────────────────────")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Europe" } }, [
                          _vm._v("Europe"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Middle East" } }, [
                          _vm._v("Middle East"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Asia" } }, [
                          _vm._v("Asia"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Africa" } }, [
                          _vm._v("Africa"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "North America" } }, [
                          _vm._v("North America"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "South America" } }, [
                          _vm._v("South America"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Oceania" } }, [
                          _vm._v("Oceania"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "option",
                          {
                            staticClass: "quotation__select-separator",
                            attrs: { disabled: "" },
                          },
                          [_vm._v("────────────────────")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.$store.countries.all, function (country, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: country } },
                            [_vm._v(_vm._s(country))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Existing Relationships"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.existingRelationships,
                          expression: "quotation.existingRelationships",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "List any suppliers we should avoid contacting",
                      },
                      domProps: { value: _vm.quotation.existingRelationships },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "existingRelationships",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(10),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.productDescription,
                          expression: "quotation.productDescription",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__textarea",
                      attrs: {
                        placeholder:
                          "What do you want this product to be? Describe the purpose of the product.",
                        required: "",
                      },
                      domProps: { value: _vm.quotation.productDescription },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "productDescription",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "quotation__control" },
                  [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Product Focus"),
                    ]),
                    _vm._v(" "),
                    _c("QuotationProductFocus", {
                      attrs: {
                        quality: _vm.quotation.productFocusQuality,
                        price: _vm.quotation.productFocusPrice,
                        speed: _vm.quotation.productFocusSpeed,
                      },
                      on: { change: _vm.productFocusChange },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(11),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step == 3
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitQuotation($event)
                  },
                },
              },
              [
                _c("h2", { staticClass: "quotation__subheading" }, [
                  _vm._v("Quotation Information"),
                ]),
                _vm._v(" "),
                _vm._m(12),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _vm._m(13),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiPlannedOrderQuantity,
                          expression: "quotation.qiPlannedOrderQuantity",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "How big quantity in pairs is planned? Used for quoting price.",
                        required: "",
                      },
                      domProps: { value: _vm.quotation.qiPlannedOrderQuantity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiPlannedOrderQuantity",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Planned Articles"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiPlannedArticles,
                          expression: "quotation.qiPlannedArticles",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "How many different articles is planned for this product?",
                      },
                      domProps: { value: _vm.quotation.qiPlannedArticles },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiPlannedArticles",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Total Quantity"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiTotalQuantity,
                          expression: "quotation.qiTotalQuantity",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: { type: "text", placeholder: "" },
                      domProps: { value: _vm.quotation.qiTotalQuantity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiTotalQuantity",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Target price FOB/FCA"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiTargetPrice,
                          expression: "quotation.qiTargetPrice",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder: "Enter target price on FOB/FCA level.",
                      },
                      domProps: { value: _vm.quotation.qiTargetPrice },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiTargetPrice",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c(
                    "label",
                    { staticClass: "quotation__label" },
                    [
                      _c("div", { staticClass: "quotation__label-text" }, [
                        _vm._v("Size Range"),
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.quotation.qiSizeRangeFrom) +
                            " - " +
                            _vm._s(_vm.quotation.qiSizeRangeTo)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vue-slider", {
                        staticClass: "supplier__slider-slider",
                        attrs: {
                          value: [
                            _vm.quotation.qiSizeRangeFrom,
                            _vm.quotation.qiSizeRangeTo,
                          ],
                          min: 11,
                          max: 55,
                          width: "100%",
                          tooltip: false,
                          bgStyle: {
                            backgroundColor: "rgba(55, 73, 94, 0.13)",
                          },
                          sliderStyle: {
                            backgroundColor: "#fff",
                            border: "2px solid #37495e",
                          },
                          processStyle: {
                            backgroundColor: "rgba(55, 73, 94, 0.8)",
                          },
                        },
                        on: { callback: _vm.watchSizeRange },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Packaging Cost"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiPackagingCost,
                          expression: "quotation.qiPackagingCost",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          "Enter your rough cost for packaging and labels.",
                      },
                      domProps: { value: _vm.quotation.qiPackagingCost },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiPackagingCost",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Shipping Destination"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiShippingDestination,
                          expression: "quotation.qiShippingDestination",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        placeholder:
                          "Info about destination enables best supplier suggestions.",
                      },
                      domProps: { value: _vm.quotation.qiShippingDestination },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiShippingDestination",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quotation__control" }, [
                  _c("label", { staticClass: "quotation__label" }, [
                    _c("div", { staticClass: "quotation__label-text" }, [
                      _vm._v("Date to Receive Goods"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.qiDateToReceiveGoods,
                          expression: "quotation.qiDateToReceiveGoods",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "quotation__input",
                      attrs: {
                        placeholder:
                          "Add which date you want to have recieved the products.",
                      },
                      domProps: { value: _vm.quotation.qiDateToReceiveGoods },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "qiDateToReceiveGoods",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(14),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step == 4
          ? _c("div", [
              _c("h2", { staticClass: "quotation__subheading" }, [
                _vm._v("Thank you"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quotation__info" }, [
                _c("p", [
                  _vm._v(
                    "We have recieved your request and it will be distributed to relevant suppliers to get their offers."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Once matching offers are received, we will contact you."
                  ),
                ]),
                _vm._v(" "),
                _vm._m(15),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "old-form__button",
                        attrs: { to: { name: "quotationOverview" } },
                      },
                      [_vm._v("Quotation Overview")]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__info" }, [
      _c("p", [
        _vm._v(
          "\n          This first step is for the supplier to understand who the potential client is and then about the product.\n          The better understanding the supplier has about who you are, the better service you can expect.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Company Name "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Company Description "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Size of Business "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Languages Spoken "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "quotation__control quotation__control--center" },
      [
        _c(
          "button",
          { staticClass: "quotation__submit", attrs: { type: "submit" } },
          [_vm._v("Next")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__info" }, [
      _c("p", [
        _vm._v(
          "\n          Add information about what kind of product you want to develop and get quoted."
        ),
        _c("br"),
        _vm._v(
          "\n          The more detailed information, the better supplier match you´ll be able to recieve.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Product Name "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Upload Design Sketch "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Product Type "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("Product Description "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "quotation__control quotation__control--center" },
      [
        _c(
          "button",
          { staticClass: "quotation__submit", attrs: { type: "submit" } },
          [_vm._v("Next")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__info" }, [
      _c("p", [
        _vm._v(
          "\n          In order to be quoted a price we need to know some more details to ensure you are getting the best deal.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation__label-text" }, [
      _vm._v("\n            Planned Order Quantity by Article "),
      _c("span", { staticClass: "quotation__required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "quotation__control quotation__control--center" },
      [
        _c(
          "button",
          { staticClass: "quotation__submit", attrs: { type: "submit" } },
          [_vm._v("Submit")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          If you have any question send an email to\n          "
      ),
      _c("a", { attrs: { href: "mailtp:quotation@findsourcing.com" } }, [
        _vm._v("quotation@findsourcing.com"),
      ]),
      _vm._v(".\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }